import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Brain, Code, Cpu, Database, Terminal,Mail, Phone } from 'lucide-react';

const UdbahanAIWebsite = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const parallaxStyle = {
    transform: `translateY(${scrollY * 0.5}px)` // Use backticks for template literals
  };

  return (
    <div className="bg-gray-900 text-white min-h-screen font-sans">
      <header className="fixed w-full z-10 bg-gray-900 bg-opacity-80 backdrop-blur-md">
        <nav className="container mx-auto px-6 py-4 flex justify-between items-center">
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-2xl font-bold">Udbhaban.AI</h1>
          </motion.div>
          <motion.ul
            className="flex space-x-6"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <li><a href="#home" className="hover:text-blue-400 transition-colors">Home</a></li>
            <li><a href="#about" className="hover:text-blue-400 transition-colors">About</a></li>
            <li><a href="#services" className="hover:text-blue-400 transition-colors">Services</a></li>
            <li><a href="#contact" className="hover:text-blue-400 transition-colors">Contact</a></li>
          </motion.ul>
        </nav>
      </header>

      <main>
        <section id="home" className="h-screen flex items-center justify-center relative overflow-hidden">
          <div className="absolute inset-0 z-0" style={parallaxStyle}>
            <div className="absolute inset-0 bg-gradient-to-br from-blue-600 to-purple-800 opacity-50"></div>
            <motion.div
              className="absolute inset-0"
              animate={{
                backgroundPosition: ['0% 0%', '100% 100%'],
              }}
              transition={{
                duration: 20,
                repeat: Infinity,
                repeatType: 'reverse',
              }}
              style={{
                backgroundImage: 'url("data:image/svg+xml,%3Csvg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cg fill="%239C92AC" fill-opacity="0.4"%3E%3Cpath d="M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z"/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")',
              }}
            ></motion.div>
          </div>
          <div className="z-10 text-center">
            <motion.h2
              className="text-5xl font-bold mb-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Welcome to Udbhaban.AI
            </motion.h2>
            <motion.p
              className="text-xl mb-8"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Revolutionizing the future with AI
            </motion.p>
            <motion.button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition-colors"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Learn More
            </motion.button>
          </div>
        </section>

        <section id="about" className="py-20 bg-gray-800">
          <div className="container mx-auto px-6">
            <motion.h3
              className="text-3xl font-bold mb-8 text-center"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              About Us
            </motion.h3>
            <motion.p
              className="text-lg mb-8 text-center max-w-2xl mx-auto"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              At Udbhaban, we turn your dreams into reality. Our team of experts is dedicated to providing exceptional service and delivering innovative solutions that exceed your expectations.
            </motion.p>
          </div>
        </section>

        <section id="services" className="py-20">
          <div className="container mx-auto px-6">
            <motion.h3
              className="text-3xl font-bold mb-12 text-center"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Our Services
            </motion.h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                { icon: Brain, title: "AI Consulting", description: "Expert guidance on AI integration" },
                { icon: Code, title: "Custom AI Solutions", description: "Tailored AI applications for your needs" },
                { icon: Cpu, title: "Machine Learning", description: "Advanced ML models and algorithms" },
                { icon: Terminal, title: "Software Development", description: "Cutting-edge software solutions" },
                { icon: Database, title: "Data Analytics", description: "Insights from your data" }
              ].map((service, index) => (
                <motion.div
                  key={index}
                  className="bg-gray-800 p-6 rounded-lg text-center"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <service.icon className="w-12 h-12 mx-auto mb-4 text-blue-400" />
                  <h4 className="text-xl font-bold mb-2">{service.title}</h4>
                  <p>{service.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        <section id="contact" className="py-20 bg-gray-800">
          <div className="container mx-auto px-6">
            <motion.h3
              className="text-3xl font-bold mb-8 text-center"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Contact Us
            </motion.h3>
            <motion.div
              className="max-w-lg mx-auto text-center"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <div className="flex items-center justify-center mb-4">
                <Mail className="w-6 h-6 mr-2 text-blue-400" />
                <p>udbhabanai@gmail.com</p>
              </div>
              <div className="flex items-center justify-center mb-4">
                <Phone className="w-6 h-6 mr-2 text-blue-400" />
                <p>8638372157, 9365895010</p>
              </div>
              <p className="mt-4">Feel free to reach out to us for any inquiries or collaborations. We're here to help!</p>
            </motion.div>
          </div>
        </section>
      </main>

      <footer className="bg-gray-900 py-6">
        <div className="container mx-auto px-6 text-center">
          <p>&copy; 2024 Udbhaban.AI. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default UdbahanAIWebsite;